import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { PessoaService } from '../integration/pessoa.service';
import { EntidadeSocialService } from '../integration/entidade-social.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  userLogado = new BehaviorSubject<any>(null)
  primeiroAcesso: boolean = true

  constructor(private userService: PessoaService, private entidadeService: EntidadeSocialService) { 
    this.initToken()
  }

  initToken(){
    const token = localStorage.getItem('AccessToken');
    if (token) {
       const decodedToken = jwtDecode(token);

      const tokenId: any = decodedToken;
      if (tokenId.Profile == "Instituição Comum"){
        this.entidadeService.getInstitution(tokenId.Id).subscribe({
          next: response => {
            let value: any = response;
            value.id = tokenId.Id;
            value.tag = tokenId.Tag;
            value.permissions = tokenId.Permissions
            value.profile = tokenId.Profile
            this.setToken(value);
          },
          error: (err: HttpErrorResponse) => {
            if (err.error.exception == 'Token invalido.'){
              localStorage.removeItem('AccessToken')
              this.setToken(null)
            }
          },
          complete: () => {}
        });
      }
      else {
        this.userService.getUser(tokenId.Id).subscribe({
          next: response => {
            let value: any = response;
            value.id = tokenId.Id;
            value.tag = tokenId.Tag;
            value.permissions = tokenId.Permissions
            value.profile = tokenId.Profile
            this.setToken(value);
          },
          error: (err: HttpErrorResponse) => {
            if (err.error.exception == 'Token invalido.'){
              localStorage.removeItem('AccessToken')
              localStorage.removeItem('jwtToken')
              this.setToken(null)
            }
          },
          complete: () => {}
        });
        
      }
    }
  }

  setToken(item: any){
    this.userLogado.next(item)
  }

  getToken(){
    if (this.userLogado){
    return this.userLogado.getValue()
    }
    else {
      return null
    }
  }

  getDecodedToken(){
    
  }
}